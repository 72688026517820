.nirby-card {
    // STYLES
    background-color: white;
    border-radius: 12px;
    padding: 1rem;
    overflow: hidden;

    // RESPONSIVE (BOTTOM UP)
    @media (min-width: $break-xs) {
    }
    @media (min-width: $break-sm) {
    }
    @media (min-width: $break-md) {
        padding: 1.5rem;
    }
    @media (min-width: $break-lg) {
    }
    @media (min-width: $break-xlg) {
    }

    // RESPONSIVE (TOP DOWN)
    @media (max-width: $break-xlg) {
    }
    @media (max-width: $break-lg) {
    }
    @media (max-width: $break-md) {
    }
    @media (max-width: $break-sm) {
    }
    @media (max-width: $break-xs) {
    }
}
