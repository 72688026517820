/* ----------------------------------------------------
---------------------- Generales ----------------------
---------------------------------------------------- */
html,
body {
    overflow-x: hidden;
}
html body {
    // overflow-x: hidden;
    overflow: visible; // Esto es para que position: sticky funcione correctamente
    margin: 0;
    padding: 0;
    background: transparent;
    background-color: $color-grey-darker;
    font-family: $font-default, 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
}
a {
    color: $color-brand-primary;

    &:hover {
        color: darken($color-brand-primary, 20%);
    }
}

/* -----------------------------------------------------
------------------------- Main -------------------------
----------------------------------------------------- */

/* -----------------------------------------------------
------------------------- Misc -------------------------
----------------------------------------------------- */
#logo-responsive {
    background-color: $color-brand-primary;

    img {
        max-width: 100px;
        width: 50%;
        height: auto;
    }
}
.font-family-default {
    @include font-family-default();
}
.font-family-alt {
    @include font-family-alt();
}

/*--------------------------------------------------------------------------
---------------------------- Base - Responsive -----------------------------
--------------------------------------------------------------------------*/
// RESPONSIVE (BOTTOM UP)
@media (min-width: $break-xs) {
}
@media (min-width: $break-sm) {
}
@media (min-width: $break-md) {
    // html body {
    //     padding-top: 2.5rem;
    // }
}
@media (min-width: $break-lg) {
}
@media (min-width: $break-xlg) {
}

// RESPONSIVE (TOP DOWN)
@media (max-width: $break-lg) {
}
@media (max-width: $break-md) {
}
@media (max-width: $break-sm) {
}
@media (max-width: $break-xs) {
}
@media (min-width: $break-md) {
}
