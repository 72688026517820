@use '@material/slider/styles';

@import 'libs/styles/assets/scss/styles';
@import 'libs/creators/root/src/lib/styles/styles';

// Plugins
@import '~highlight.js/styles/github.css';

@import 'primeicons/primeicons.css';
@import 'primeng/resources/themes/tailwind-light/theme.css';
@import 'primeng/resources/primeng.min.css';

.prime-player-layer > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    pointer-events: auto;
}

.firebase-emulator-warning {
    opacity: 0.3;
    pointer-events: none;
}

.p-sidebar-right {
    width: 40rem !important;
    max-width: 100%
}
