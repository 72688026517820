.nby-modal-plans {
    // STYLES
    .plan-comparison {
        .title {
            font-size: 1.4rem;
            font-weight: bold;
            color: $color-grey-dark;
        }
        .pricing {
            font-weight: bold;
            font-size: 1.8rem;
            color: $color-grey-dark;

            .period {
                font-weight: normal;
                font-size: 0.5em;
                color: $color-grey;
            }
        }
        .feature {
            @include transition(background-color 0.2s 0.1s);
            background-color: $color-milkier;
            color: $color-grey;
            border-radius: 4px;
            // height: 4rem;

            span {
                display: block;
            }
            .text {
                font-size: 0.85rem;
            }
            .data {
                font-size: 1.15rem;
                font-weight: bold;
                color: $color-brand-primary;
            }

            &.feature-simple {
                background-color: transparent;
                height: auto;

                .data {
                    color: $color-grey;
                }
            }
        }
        .features {
            &:hover {
                .feature {
                    background-color: $color-milky;

                    &.feature-simple {
                        background-color: $color-milkier;
                    }
                }
            }
        }
    }

    // RESPONSIVE (BOTTOM UP)
    @media (min-width: $break-xs) {
    }
    @media (min-width: $break-sm) {
    }
    @media (min-width: $break-md) {
        .plan-business {
            .actions {
                min-width: 160px;
            }
        }
    }
    @media (min-width: $break-lg) {
    }
    @media (min-width: $break-xlg) {
    }

    // RESPONSIVE (TOP DOWN)
    @media (max-width: $break-xlg) {
    }
    @media (max-width: $break-lg) {
    }
    @media (max-width: $break-md) {
    }
    @media (max-width: $break-sm) {
    }
    @media (max-width: $break-xs) {
    }
}
