// TODO: Check if this is necessary in this package (@nirby/styles)

.owl-dt-timer {
    // STYLES
    background-color: $color-milkier;
    border-radius: 12px;

    // RESPONSIVE (BOTTOM UP)
    @media (min-width: $break-xs) {
    }
    @media (min-width: $break-sm) {
    }
    @media (min-width: $break-md) {
    }
    @media (min-width: $break-lg) {
    }
    @media (min-width: $break-xlg) {
    }

    // RESPONSIVE (TOP DOWN)
    @media (max-width: $break-xlg) {
    }
    @media (max-width: $break-lg) {
    }
    @media (max-width: $break-md) {
    }
    @media (max-width: $break-sm) {
    }
    @media (max-width: $break-xs) {
    }
}
