
input.form-control {
    border: 0;
    background-color: #dadfea;
    color: #686879;
    border-radius: 7px;
}

label.col-form-label, label {
    font-size: 14px;
    color: #282837;
    font-weight: 600;
}

.alert.alert-text {
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: small;
    margin: 0.2rem 0 0;
}
