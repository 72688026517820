.btn.btn-tag {
    display: flex;
    flex-direction: row;
    padding: 0.1rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.75rem;
    background-color: #f5f5f5;
    color: #333;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
}

.btn.btn-tag .ng-fa-icon {
    margin-right: 0.5rem;
    width: 18px;
}

.btn.btn-tag span {
    min-width: 52px;
}
