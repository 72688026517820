// Bootstrap Modules
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

// Nirby Variables
@import 'core/variables';

@import 'bootstrap/dist/css/bootstrap.css';

@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/utilities';

// CORE
@import 'core/mixins';
@import 'core/extend_bootstrap';
@import 'core/base';

// Directives
@import 'directives/nirby-card';
@import 'directives/nby-modal';
@import 'directives/owl-dt-timer';
@import 'directives/inputs';

.btn-block {
    width: 100%;
}

.btn-transparent {
    background-color: transparent;
    border: none;
    color: white;
}

.btn-danger {
    color: white;

    &:hover {
        color: white;
    }
}
