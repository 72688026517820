.dashboard-projector {
    // STYLES

    .nby-events-box-set {
        border-radius: 8px;

        .metrics-data {
            .metrics-data-title {
                font-size: 18px;
                font-weight: bold;
            }
            .metrics-data-numbers {
                .number {
                    font-size: 24px;
                    height: 35px;
                }
            }
            .change-status,
            .change-fluctuation {
                font-size: 12px;
            }
        }
        .metrics-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-brand-primary;
            width: 32px;
            height: 32px;
            border-radius: 10px;
            overflow: hidden;
            font-size: 0.8rem;

            .icon {
                color: white;
            }
        }
        &.metrics-light {
            .metrics-data-title {
                color: $color-grey-dark;
            }
            .metrics-data-numbers {
                color: $color-grey-darker;
            }
            .change-fluctuation {
                color: $color-grey-lighter;
            }
        }
        &.metrics-dark {
            background-color: $color-grey-darkest;

            .metrics-data-title {
                color: $color-grey-lighter;
            }
            .metrics-data-numbers {
                color: #ffffff;
            }
            .change-fluctuation {
                color: $color-grey;
            }
        }
    }

    .projector-header {
        border-bottom: 1px $color-milky solid;

        .projector-header-title {
            font-weight: bold;
            font-size: 16px;
            color: $color-grey-darker;
        }
    }

    .nby-summary-container {
        background-color: #e5e7f0c0;
        border-top: 1px solid #d9dbe4;
    }
    // .opportunities-overview-container {
    //     .metrics-data-title {
    //         font-size: 24px;
    //         color: #434353;
    //     }
    //     .metrics-data-numbers {
    //         font-size: 24px;
    //         color: #313136;
    //     }
    // }
    .projector-body {
        // padding: 1rem;
    }

    .month-selector {
        width: 140px;
        height: 25px;
        padding: 2px;
        font-size: 15px;
        background: #f5f6fa;
        border-color: #f5f6fa;
        color: #abacb7;
    }

    // .overview-data {
    //     font-size: 16px;
    //     font-weight: bold;
    //     color: #434353;
    // }

    // RESPONSIVE (BOTTOM UP)
    @media (min-width: $break-xs) {
    }
    @media (min-width: $break-sm) {
    }
    @media (min-width: $break-md) {
    }
    @media (min-width: $break-lg) {
    }
    @media (min-width: $break-xlg) {
    }

    // RESPONSIVE (TOP DOWN)
    @media (max-width: $break-xlg) {
    }
    @media (max-width: $break-lg) {
    }
    @media (max-width: $break-md) {
    }
    @media (max-width: $break-sm) {
    }
    @media (max-width: $break-xs) {
    }
}
