// Angular Bootstrap
.nav,
.pagination,
.carousel,
.panel-title a {
    cursor: pointer;
}

[ng\:cloak],
[ng-cloak],
.ng-cloak {
    display: none;
}

/* ============= Buttons ============= */
.btn {
    font-weight: bold;
    border-radius: 4px;
    // padding: 0.375rem 1rem;

    // &.btn-sm {
    //     height: 30px;
    //     padding: 0.3rem 0.7rem;
    // }
    &.btn-outline-light,
    &.btn-outline-dark,
    &.btn-outline-info,
    &.btn-outline-warning,
    &.btn-outline-danger,
    &.btn-outline-primary,
    &.btn-outline-secondary {
        border-width: 2px;
    }
}
.btn-radius {
    border-radius: 25px;
}
.btn-circle {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;

    &.btn-circle-xs {
        width: 32px;
        height: 32px;
    }
    &.btn-circle-sm {
        width: 50px;
        height: 50px;
    }
    &.btn-circle-md {
        width: 75px;
        height: 75px;
    }
    &.btn-circle-lg {
        width: 90px;
        height: 90px;
    }
}
.btn-icon {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn-link {
    color: $color-brand-primary;

    &:hover {
        color: darken($color-brand-primary, 20%);
    }
}
.btn-brand-primary {
    color: white;
    background-color: $color-brand-primary;
    border-color: $color-brand-primary;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &.dropdown-toggle {
        color: white;
        background-color: darken($color-brand-primary, 10%);
        border-color: darken($color-brand-primary, 10%);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 0.2rem lighten($color-brand-primary, 20%);
    }
    &:hover {
        color: white;
        background-color: darken($color-brand-primary, 10%);
        border-color: darken($color-brand-primary, 10%);
    }
}
.btn-brand-secondary {
    color: $color-brand-primary;
    background-color: $color-brand-secondary;
    border-color: $color-brand-secondary;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &.dropdown-toggle {
        color: $color-brand-primary;
        background-color: darken($color-brand-secondary, 10%);
        border-color: darken($color-brand-secondary, 10%);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 0.2rem lighten($color-brand-secondary, 20%);
    }
    &:hover {
        color: $color-brand-secondary;
        background-color: $color-brand-primary;
        border-color: $color-brand-primary;
    }
}
.btn-gradient-primary {
    color: white;
    background-color: $color-brand-primary;
    background-image: linear-gradient(
        to right,
        $color-brand-green,
        $color-brand-blue
    );
    border: none;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &.dropdown-toggle {
        color: white;
        background-color: darken($color-brand-primary, 10%);
        border-color: darken($color-brand-primary, 10%);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 0.2rem lighten($color-brand-primary, 20%);
    }
    &:hover {
        color: white;
        background-image: linear-gradient(
            to right,
            darken($color-brand-green, 10%),
            darken($color-brand-blue, 10%)
        );
        border: none;
    }
}
/* FIN Buttons */

// Modal
.modal-backdrop {
    z-index: 29980 !important;
}
.modal {
    z-index: 29990 !important;
}
.modal-content {
    border: none;
}

// Pagination
.pagination {
    .page-item {
        .page-link {
            padding: 0.4rem 0.75rem;
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.2;
        }
        &.active {
            .page-link {
                background-color: $color-brand-secondary;
                color: $color-brand-primary;
            }
        }
    }
    .page-link {
        border: none;
        font-weight: bold;
        font-size: 16px;
        color: $color-brand-primary;
    }
}

/* ============= Labels ============= */
span.label,
div.label {
    &.label-bordered {
        background: none;

        &.label-danger {
            border: 1px $red solid;
            color: $red;
        }
    }
    &.label-xs {
        font-size: 10px;
    }
}
.label-default {
    background-color: $color-grey-light;
}
/* FIN Labels */

/* ============= Form ============= */
.input-group {
    .form-control {
        height: 35px;
    }
}
.form-group {
    // input.form-control, select.form-control {
    //     border-radius: 0;
    //     border-color: $color-grey-lighter;
    //     @include input-placeholder($color-grey-lighter);

    //     &.form-control-radius {
    //         border-radius: 25px;
    //         padding: 0.375rem 1.1rem;
    //     }
    //     &.form-control-contrast {
    //         border-color: white;
    //         background-color: transparent;
    //         color: white;
    //         @include input-placeholder($color-grey-light);
    //     }
    // }
    label {
        text-align: left;
        font-weight: bold;
        font-size: 12px;
        color: $color-grey-dark;
        margin-bottom: 0.2rem;

        &.label-contrast {
            color: white;
        }
    }

    /* enable absolute positioning */
    .inner-addon {
        position: relative;

        .inner-addon-item {
            position: absolute;
            pointer-events: none;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
        &.clickable {
            .inner-addon-item {
                pointer-events: auto;
            }
        }
        &.left-addon {
            .inner-addon-item {
                left: 0px;
            }
            input {
                padding-left: 30px;
            }
        }
        &.right-addon {
            .inner-addon-item {
                right: 0px;
            }
            input {
                padding-right: 30px;
            }
        }
    }
}
/* FIN Form */

/* ============= Tooltip ============= */
.tooltip {
    &.in {
        @include opacity(1);
    }
    .tooltip-arrow {
    }
    .tooltip-inner {
        font-family: 'Lato', sans-serif;
        background-color: black;
        font-size: 1em;
        color: $color-grey-lightest;
        border-radius: 0;
        padding: 0.6em 1em;
    }
}
/* Tooltip */

/* ============= Dropdown ============= */
.dropdown-menu {
    // border: none;
    // border-radius: 0;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

    .dropdown-header {
        font-size: 10.5px;
        color: $color-grey-lighter;
        padding: 0.3rem 1.5rem;
    }
    .dropdown-item {
        font-size: 14px;
        color: $color-grey-light;
        padding: 0.4rem 1.5rem;

        &.dropdown-item-pink {
            color: $color-brand-primary;
        }
        &.active,
        &:active {
            color: white;
            background-color: $color-brand-primary;
        }
    }
    .active {
        background-color: $blue;

        a {
            color: white;
        }
    }
}
// .dropdown-filter {
//     top: -1em;
//     left: auto;
//     right: -50%;
//     padding-top: 0.8em;
//     padding-bottom: 0.8em;
//     border-radius: 0;
//     border: none;
//     box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);

//     &.left {
//         right: auto;
//         left: 0;
//     }

//     ul {
//         margin: 2em 0 0;

//         &:first-child {
//             margin-top: 0;
//         }
//     }
//     li {
//         color: $color-grey-light;
//         font-size: 14px;

//         &.dropdown-header {
//             font-size: 0.8em;
//             color: $color-grey-lighter;
//         }

//         a,
//         label {
//             font-weight: normal;
//             display: block;
//             padding: 0.5em 1.3em 0.5em 1.3em;
//             color: $color-grey-light;
//         }
//         .icono {
//             margin-right: 0.5em;
//         }
//         &.active {
//             a {
//                 background: $blue;
//                 color: white;
//             }
//         }

//         /* Forms */
//         label {
//             cursor: pointer;

//             &:hover {
//                 color: $color-grey-dark;
//             }

//             .icono {
//                 margin: -1px 4px 0 0;
//                 vertical-align: middle;
//             }
//         }

//         input[type="checkbox"],
//         input[type="radio"] {
//             display: none;

//             &:checked {
//                 + label .texto,
//                 ~ .texto {
//                     color: $color-grey-dark;
//                 }
//                 + label .icono,
//                 ~ .icono {
//                 }
//             }
//             &:disabled {
//                 + label .texto,
//                 ~ .texto {
//                     color: $color-grey-lighter;
//                     opacity: 0.5;
//                     cursor: not-allowed;

//                     &:hover {
//                         color: $color-grey-lighter;
//                     }
//                 }
//             }
//         }
//     }
// }
/* FIN Dropdown */

/* ============= Carousel ============= */
.carousel {
    .carousel-inner {
        > div {
            height: 100%;
        }
    }
}
.carousel-indicators {
    li {
        margin: 1px 0.3em;

        &.active {
            margin: 0 0.3em;
        }
    }
}
/* FIN Carousel */

ngx-select {
    .ngx-select__placeholder {
        font-weight: normal;
        color: $color-grey-lighter !important;
    }
    .ngx-select__toggle {
        font-weight: normal;
    }
}

/* ============= Navs ============= */
.tabset-flex {
    > .tab-content {
        position: relative;

        > .tab-pane {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
        }
    }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: $color-brand-primary;
}
.nav-pills {
    .nav-item:not(:last-child) {
        margin-right: 0.5rem;
    }
    .nav-link {
        background-color: $color-milkier;
        color: $color-grey;
        font-weight: bold;
        font-size: 0.95rem;
        padding: 0.2rem 0.8rem;
    }
}
.nav-tabs {
    text-align: center;

    > li {
        font-size: 14px;
        float: none;
        display: inline-block;

        // Actual tabs (as links)
        > a {
            padding: 0.5rem 1rem;
            color: $color-grey-lighter;
            border: none !important;
            border-radius: 0;
            font-size: 16px;
            text-align: center;
            line-height: 22px;

            .icon {
                display: block;
                font-size: 24px;
                margin-bottom: 0.5rem;
            }

            &:hover {
                background: none;
                border: none;
                color: $color-grey;
            }
        }

        // Active state, and its :hover to override normal :hover
        > a,
        .nav-link {
            &.active {
                color: $color-brand-primary;
                background: none;
                border: none;
                border-radius: 0;
                border-bottom: 4px $color-brand-primary solid !important;

                > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $color-grey;
                        background: none;
                        border: none;
                        border-bottom: 3px $blue solid;
                        cursor: default;
                    }
                }
            }
        }
        &.nav-tab-contrast {
            &.active {
                > a {
                    &,
                    &:hover,
                    &:focus {
                        color: white;
                        background: rgba(0, 0, 0, 0.2);
                        font-weight: lighter;
                    }
                }
            }
        }
        &.only-icons {
            > a {
                .icono {
                    bottom: auto;
                    left: auto;
                }
            }
        }
    }
}

// RESPONSIVE
@media (min-width: $break-md) {
}

@media (max-width: $break-md) {
}

@media (max-width: $break-sm) {
}

@media (max-width: $break-xs) {
}
